<template>
	
	<div>
		<PicCaptcha ref="picCaptcha"></PicCaptcha>
		<div class="login_box" :style="{ right: rightP }">
			
		    <!-- middle -->
		    <form class="input_lay">
		      <!-- 验证码登录 密码登录 -->
		      <div class="flex_column">
		        <!-- 输入手机号 -->
		        <input
		          class="loginInput"
		          type="text"
		          placeholder="请输入手机号"
		          v-model="mobile"
		          maxlength="11"
		          @keyup.enter="login"
		        />
		        <!-- 输入密码 -->
		        <input
		          v-if="loginType == 'pwd'"
		          type="password"
		          class="loginInput"
		          placeholder="请输入密码"
		          v-model="password"
		          autocomplete="off"
		          @keyup.enter="login"
		        />
		        <!-- 输入验证码 -->
		        <div class="relative" v-if="loginType == 'sms'">
		          <input
		            class="loginInput"
		            type="text"
		            placeholder="验证码"
		            v-model="smsCode"
		            maxlength="6"
		            @keyup.enter="login"
		          />
		          <!-- <GetCode :mobile="mobile" type="login" top="8" /> -->
				  <div class="code_btn" @click="getSmsCode" :style="{ top: top + 'px' }">
				      {{ sendCode ? "已发送（" + time + "s）" : "发送验证码" }}
				    </div>
		        </div>
		        <div
		          class="flex-e-c colorlightBlue font12 margintop10 marginbottom10 cursor_pointer"
		          @click="
		            loginType == 'sms' ? (loginType = 'pwd') : (loginType = 'sms')
		          "
		        >
		          {{ loginType == "sms" ? "密码登录" : "验证码登录" }}
		        </div>
		        <!-- 登录按钮 -->
		        <el-button class="submit" type="primary" @click="login">{{
		          logining ? "登陆中..." : "登录"
		        }}</el-button>
		        <!-- <el-button
		          class="submit"
		          @click="router.push( { path: '/register' })"
		          style="margin: 10px 0; color: #409eff; border-color: #409eff"
		          >注册</el-button -->
				  <el-button
				    class="submit"
				    @click="toRegister()"
				    style="margin: 10px 0; color: #409eff; border-color: #409eff"
				    >注册</el-button
		        >
		      </div>
		    </form>
		  </div>
		<el-dialog :visible.sync="showOrgan" width="30%">
			<div class="list_lay" v-if="showOrgan">
			  <div class="textTitle">
				请选择您要登录的学校：
				<el-input
				  @input="beforChangeOrganList"
				  v-show="this.saveOrganList.length > 10"
				  size="mini"
				  style="width: 150px"
				  v-model="organInput"
				  placeholder="请输入搜索关键字"
				></el-input>
			  </div>
			  <div id="loginOrganListBox">
				<el-button
				  class="width100"
				  style="margin: 10px 0; height: 40px"
				  v-for="item in organList"
				  :key="item.id"
				  @click="
					toOrgan(item.organId, item.id, item.pmuserId, item.organDomain.name)
				  "
				  :type="chosenOrganId == item.organId ? 'primary' : ''"
				>
				  <span>{{ item.organDomain.name }}</span>
				  <span v-if="chosenOrganId == item.organId">√</span>
				</el-button>
			  </div>
			</div>
		</el-dialog>
	</div>
	
  
	
</template>
<script>
	import qs from "qs";
	import { Encrypt } from "@/util/secret.js";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { queryImageVideos,queryLoginUserByUserId } from "@/axios/api";

import {
	queryUserOwnOrgan,
  upwLogin,
  smsLogin,
  addUserUseOrgan,
  queryAndAddOwnInitUserQuestionBanks,
  isSmsCaptcha,
  getSmsCode
} from "@/axios/login";
import storage from "good-storage";
import home1 from "@/assets/imgs/home1.png";
import PicCaptcha from "@/components/picCaptcha.vue";
export default {
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
  	PicCaptcha
  },
  data() {
    return {
		swiper_isshow: false,
		userInfo:null,
		
		DEFPNG:'@/assets/imgs/default_head.jpg',
		banners:[],
		

		
		loginType:'pwd',
		mobile:null,
		password:null,
		logining:false,
		rightP:'7.5%',
		smsCode:null,
		
		showOrgan:false,
		
		// 机构列表
		      organList: [],
		      userOrganList: [],
		      saveOrganList: [],
		      chosenOrganId: "",
		      organInput: "",
		      showOrgan: false,
		      changeOrganListTimer: null,
			  
		// 验证码
		time: 60,
		codeTimer: null,
		sendCode: false,
		top:8,
		
    };
  },

  directives: {
    swiper: directive,
  },
  computed: {
    
  },
  mounted() {
	  
	  
	// this._queryImageVideos()
	// this._queryLoginUserByUserId()
  },
  methods: {
	  
	   changeOrganList() {
	        if (this.organInput) {
	          this.organList = [];
	          for (const organ of this.saveOrganList) {
	            if (
	              organ.organDomain &&
	              organ.organDomain.name &&
	              organ.organDomain.name.indexOf(this.organInput) != -1
	            ) {
	              this.organList.push(organ);
	            }
	          }
	          this.chosenOrganId = this.organList[0] ? this.organList[0].organId : "";
	        } else {
	          this.organList = this.saveOrganList.slice();
	        }
	      },
	  
	  beforChangeOrganList() {
		if (this.changeOrganListTimer) {
		  clearTimeout(this.changeOrganListTimer);
		}
		this.changeOrganListTimer = setTimeout(() => {
		  this.changeOrganList();
		});
	  },
	  
	  login(){
		  if (this.mobile) {
			if (this.loginType == "pwd") {
			  // 密码登录
			  if (this.password) {
				this.logining = true;
				this.upwLogin();
			  } else {
				alert("请输入密码");
			  }
			} else {
			  // 验证码登录
			  if (this.smsCode) {
				this.logining = true;
				this.smsLogin();
			  } else {
				alert("请输入验证码");
			  }
			}
		  } else {
			alert("请输入手机号");
		  }
	  },
	  
	  upwLogin(){
		  upwLogin(
		  qs.stringify({
		            username: this.mobile,
		            password: Encrypt(this.password),
		            loginType: "OemToken",
		          })
		  ).then(res => {
		  	if (res.status == 0 && res.data  && res.data.Authorization) {
		  		storage.set("lemon_Authorization", res.data.Authorization);
				this._queryUserOwnOrgan()
		  	}else{
				this.logining = false;
				alert(res.errMsg);
			}
		  })
	  },
	  
	  // 验证码登录
	      smsLogin() {
	        if (this.smsCode.length == 6) {
	          smsLogin(
	            qs.stringify({
	              mobile: this.mobile,
	              smsCode: this.smsCode,
	              loginType: "OemToken",
	            })
	          )
	            .then((res) => {
	              if (res.status == 0) {
					  storage.set("lemon_Authorization", res.data.Authorization);
	                this._queryUserOwnOrgan();
	              } else {
	                this.logining = false;
	                alert(res.errMsg);
	              }
	            })
	            .catch((err) => {
	              console.log(err);
	              this.logining = false;
	            });
	        } else {
	          alert("请正确输入验证码");
	          this.logining = false;
	        }
	      },
	  
	      _queryUserOwnOrgan() {
	        queryUserOwnOrgan({})
	          .then((res) => {
	            if (res.status == 0) {
	              if (res.data && res.data.length == 1) {
	                this.toOrgan(
	                  res.data[0].organId,
	                  res.data[0].id,
	                  res.data[0].userId,
	                  res.data[0].organDomain.name
	                );
	              } else if (res.data && res.data.length > 1) {
	                //多机构
	                this.organList = res.data;
	                this.userOrganList= res.data;
	                this.saveOrganList = this.organList.slice();
	                let organId = storage.get("lemon_organId" + this.mobile);
	                if (organId) {
	                  for (let i = 0; i < this.organList.length; i++) {
	                    if (this.organList[i].organId == organId) {
	                      this.toOrgan(
	                        res.data[i].organId,
	                        res.data[i].id,
	                        res.data[i].userId,
	                        res.data[i].organDomain.name
	                      );
	                      return;
	                    }
	                  }
	                }
	                this.showOrgan = true;
	                this.chosenOrganId = res.data[0].organId;
	              } else {
	                alert("用户暂无机构，请联系贵单位管理员进行添加");
	                this.logining = false;
	              }
	            } else {
	              this.logining = false;
	              alert(res.errMsg);
	            }
	          })
	          .catch((err) => {
	            console.log(err);
	            this.logining = false;
	          });
	      },
		  
		  // 选择机构登录
		      toOrgan(organId, userOrganId, pmuserId, organName) {
		        this.chosenOrganId = organId;
		        this.addUserUseOrgan(organId, userOrganId, pmuserId, organName);
		      },
			  
			  addUserUseOrgan(organId, userOrganId, pmuserId, organName) {
			        addUserUseOrgan({
			          userOrganId: organId,
			        })
			          .then(() => {
			            this.logining = false;
			            let api_token = storage.get("lemon_Authorization");
			            storage.clear();
			            storage.set("lemon_organName", organName);
			            storage.set("lemon_organId", organId);
			            storage.set("lemon_userId", pmuserId);
			            storage.set("lemon_organList", this.userOrganList);
			            storage.set("lemon_organId" + this.mobile, organId);
			            storage.set("lemon_Authorization", api_token);
			            queryAndAddOwnInitUserQuestionBanks({});
						// this.showOrgan=false
						// this._queryLoginUserByUserId()
						
						this.$router.go(0)
			            // this.$router.push({
			            //   path: "/",
			            // });
			          })
			          .catch((err) => {
			            console.log(err);
			            this.logining = false;
			          });
			      },
	  
	  
	  
	  _queryLoginUserByUserId(){
		  queryLoginUserByUserId({ }).then(res => {
		  	if (res.status == 0) {
		  			this.userInfo=res.data
		  	}
		  })
	  },
	  
	  test(){
	  		  console.log('123')
	  },
   
   changeOrgan(){},
   
   toHome(){},
   
   _queryImageVideos(){
	   queryImageVideos({ type: 51 ,noToken:true}).then(res => {
	   	if (res.status == 0) {
			this.banners=res.data
	   	}
	   })
   },
   
   changePath(path){
	   console.log('__changePath1')
	   console.log('__path:'+path)
	   let { href } = this.$router.resolve({
	   	path: path,
	   	query:{
	   	  
	   	}
	   })
	   window.open(path);
   },
   
   // 获取验证码
       async getSmsCode() {
         if (!this.sendCode) {
           this.time = 60;
   
           if (
             this.mobile.match(
               /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[189])\d{8}$/
             )
           ) {
             try {
               let res = await isSmsCaptcha({
                 mobile: this.mobile,
                 loginType: "OemToken",
               });
               if (res.data) {
                 // 生成一个验证码对象
                 // CaptchaAppId：登录验证码控制台，从【验证管理】页面进行查看。如果未创建过验证，请先新建验证。注意：不可使用客户端类型为小程序的CaptchaAppId，会导致数据统计错误。
                 //callback：定义的回调函数
                 // console.log(window.TencentCaptcha, 1);
   
                 //腾讯的图形验证码
                 // var captcha = new window.TencentCaptcha(
                 //   "193008737",
                 //   this.picCodeCallBack,
                 //   {}
                 // );
                 // // 调用方法，显示验证码
                 // captcha.show();
   
                 //张玉的图形验证码
                 this.$refs.picCaptcha.init(this.picCodeCallBack);
               } else {
                 //腾讯的图形验证码
                 this.picCodeCallBack({ ret: 0 });
                 //张玉的图形验证码
                 // this.$refs.picCaptcha.init(this.picCodeCallBack);
               }
             } catch (error) {
               // 加载异常，调用验证码js加载错误处理函数
               alert("安全码验证失败", "提示");
             }
           } else {
             alert("请填写正确的手机号");
           }
         } else {
           return false;
         }
       },
	   
	   picCodeCallBack(res) {
		 if (res.ret === 0) {
		   if (res.errorCode) {
			 alert("安全码验证失败", "提示");
		   } else {
			 if (this.codeTimer) {
			   clearInterval(this.codeTimer);
			   this.codeTimer = null;
			 }

			 getSmsCode({
			   mobile: this.mobile,
			   ...res,
			 }).then(() => {
			   this.sendCode = true;
			   let _that = this;
			   this.codeTimer = setInterval(() => {
				 if (_that.time <= 0) {
				   clearInterval(this.codeTimer);
				   _that.sendCode = false;
				 }
				 _that.time--;
			   }, 1000);
			 });
		   }
		 }
	   },
	   
	   toRegister(){
		   window.open(location.origin+'/bkt/register');
	   }
   
   
  },
};
</script>

<style>
	
.login_box {
  padding: 24px;
  position: fixed;
  /* top: 104px; */
  top: 144px;

  width: 198px;
  /* height: 262px; */
  background: #ffffff;
  border-radius: 6px;
  z-index: 99;
}
.loginInput {
  flex: 1;
  margin-bottom: 10px;
  height: 40px;
  line-height: 40px;
  border: none;
  border-bottom: 1px solid #dcdee0;
}

.textTitle {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
}
.list_lay {
  width: 550px;
  margin: 0 auto;
  /* margin-top: 80px; */
  
  background-color: #ffffff;
  
 /* position: fixed;
  top: 144px;
  z-index: 99; */
}
#loginOrganListBox {
  max-height: calc(100vh - 80px);
  overflow: auto;
  padding-bottom: 30px;
}

.code_btn {
  position: absolute;
  padding: 5px;
  right: 0;
  border-radius: 10px;
  color: #409eff;
}
</style>
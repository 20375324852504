<template>
  <div >
  <el-container class="flex_column  top_frame">
      <el-header class="flex-b-c backgroundColorButtonPlain">		  
		  <div >
			<span v-if="userInfo" class="colorlightBlue font24 flex-s-c" @click="changeOrgan">
			  <img
				v-if="userInfo"
				:src="userInfo.organDomain.organLogo"
				style="margin-right: 5px; height: 40px"
			  />
			  {{ userInfo.organDomain.name }}</span
			>
		  </div>
		  
		<div class="flex-e-c">
			<div class="to_home" @click="$router.go(-1);">首页</div>
			<div ref="userInfoDom" class="flex-c-c">
				<img 
				class="teacherHead"
				:src="userInfo && userInfo.headPortraitUrl ? userInfo.headPortraitUrl : `${require('@/assets/imgs/default_head.jpg')}`"
				alt=""
				style="margin-left: 40px"
				/>
				
				<!-- <img v-if="userInfo" class="teacherHead" :src="userInfo.headPortraitUrl"/>
				<img v-else class="teacherHead" :src="`${require('@/assets/imgs/default_head.jpg')}`" /> -->
				
				<div v-if="userInfo && userInfo.id" class="fontBlack" style="margin-left: 16px; margin-right: 40px">
					<div class="marginbottom5">
					  {{ userInfo.name }}
					</div>
					<div class="colorlightBlue">
					  {{ userInfo.subjectName }}
					  <span>{{ userInfo.primaryGradeName }}</span>
					</div>
				</div>
				<div v-if="userInfo" @click="logout">
					退出
				</div>
			  <div v-else @click="toHome">未登录</div>
			</div>
		</div>
	  </el-header>		  
	</el-container>
	
	<Login v-if="!userInfo" @checkWsToken="checkWsToken()"></Login>
	<el-carousel height="398px" style="padding-top: 50px;">
		<el-carousel-item   v-for="item in banners"
		 :key="item.id">
		 <div class="flex-c-c">
		   <img
	  
		:src="item.imageUrl"
	  /> 
		 </div>
			 
		</el-carousel-item>
	</el-carousel>

	<div class="content_frame" >
		  <div class="width100 flex-s-c" style="max-width: 1644px; padding-top: 20px; margin-left: 70px;">
			  <div v-for="item in jumpPic"  @click="changePath(item.path,item.routerName)" style="margin-right: 40px;">
				  <img
				  
				    :src="item.src"
				    style="width: 381px; cursor: pointer;"
				    :key="item.path"
				     />
			  </div>
		  </div>
		
		<div class="other_function">
			<div class="other_function_title">亮点功能</div>
			
			<div class="function_frame" >
				<div class="function_item" v-for="item in functionArr" @click="changePath(item.path,item.routerName)">
					<div class="function_item_top">
						<img :src="item.src" style="width: 48px;cursor: pointer;"/>
						<div class="function_item_name" style="">{{item.name}}</div>
					</div>
					<div class="function_item_bottom">
						{{item.desc}}
					</div>
				</div>
			</div>
		</div>
		
	</div>
	
  </div>
</template>
<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { queryImageVideos,queryLoginUserByUserId,queryOwnHiddenMenus,queryOwnBTHwCounts,queryOwnKbSetBTV2,addUserCache } from "@/axios/api";
import {logout} from "@/axios/login";
//登录框
import Login from "../component/Login.vue";
import newFile from "@/views/component/new_file.vue";
import storage from "good-storage";
import home1 from "@/assets/imgs/home1.png";
import home2 from "@/assets/imgs/home2.png";
import home3 from "@/assets/imgs/home3.png";
import home4 from "@/assets/imgs/home4.png";

import f1 from "@/assets/imgs/f1.png";
import f2 from "@/assets/imgs/f2.png";
import f3 from "@/assets/imgs/f3.png";
import f4 from "@/assets/imgs/f4.png";
import f5 from "@/assets/imgs/f5.png";
import f6 from "@/assets/imgs/f6.png";
export default {
  components: {
	  Swiper,
	  SwiperSlide,
    Login,
	newFile
  },
  data() {
    return {
		swiper_isshow: false,
		userInfo:null,
		
		DEFPNG:'@/assets/imgs/default_head.jpg',
		banners:[],
		
		jumpPic:[
			// { src: home1, path: "/bkt/question_bank" },
			//   { src:home2, path: "/bkt/old_test" },
			//   { src: home3, path: "/bkt/sch_lib/sch_qb" },
			//   { src: home4, path: "/tm/jjkj/classroom" }
		],
		
		functionArr:[
			// { src: f1, path: "/bkt/my_lib/my_test ",name:'我的资源',desc:'通过教辅发布作业' },
			// { src: f2, path: "/question_bank",name:'一键上传',desc:'自有word/PDF文件上传' },
			// { src: f3, path: "/question_bank",name:'班级管理',desc:'通过教辅发布作业' },
			// { src: f4, path: "/question_bank",name:'错题管理',desc:'通过教辅发布作业' },
			// { src: f5, path: "/question_bank",name:'在线批改',desc:'通过教辅发布作业' },
			// { src: f6, path: "/question_bank",name:'数据画像',desc:'通过教辅发布作业' },
		]
    };
  },

  directives: {
    swiper: directive,
  },
  computed: {
    
  },
  mounted() {
	  console.log('___home mounted:'+storage.get("lemon_Authorization"))
	  
	  
	this._queryImageVideos()
	
	
	this._queryLoginUserByUserId()
	
	
	//window.open(location.origin + "/tm/jjkj/classroom?v=" + new Date().getTime(), "_self");
  },
  methods: {
	  
	  checkWsToken(){
		  console.log('_____checkWsToken')
		  this._queryLoginUserByUserId()
	  },
	  
	  _queryOwnHiddenMenus(){
		  
		  let arr=[
		  	// { src: home2, path: "/tm/jjkj/ahp_class_manager" },
		  	//   { src:home3, path: "/bkt/old_test" },
		  	//   { src: home4, path: "/bkt/sch_lib/sch_qb" },
		  	  //{ src: home4, path: "/tm/jjkj/classroom" }
		  ]
		  
		  let arr2=[
		  	{ src: f1, path: "/bkt/my_lib/my_test ",name:'我的资源',desc:'通过教辅发布作业',routerName:'my_test' },
		  	//{ src: f2, path: "/question_bank",name:'一键上传',desc:'自有word/PDF文件上传' },
		  	//{ src: f3, path: "/question_bank",name:'班级管理',desc:'通过教辅发布作业' },
		  	//{ src: f4, path: "/question_bank",name:'错题管理',desc:'通过教辅发布作业' },
		  	
		  	//{ src: f6, path: "/question_bank",name:'数据画像',desc:'通过教辅发布作业' },
		  ]
		  
		  if(this.userInfo==null){
			  arr.push({ src: home1, path: "/tm/jjkj/classroom",routerName:'classroom' })
			  arr.push({ src: home2, path: "/tm/jjkj/ahp_report",routerName:'ahp_report' })
			  arr.push({ src:home4 , path: "/bkt/oc_manage/manage_prepare",routerName:'manage_prepare' })//教研管理
			  arr.push({ src: home3, path: "/bkt/open_course/prepare",routerName:'prepare' })//我的教研
			  
			  arr2.push({ src: f3, path: "/tm/jjkj/class_manager_class",name:'班级管理',desc:'通过教辅发布作业' ,routerName:'class_manager_class'})
			  arr2.push({ src: f5, path: "/jjzx/tm/correct/handwriting",name:'在线批改',desc:'通过教辅发布作业' ,routerName:'correct_handwriting'})
			  arr2.push({ src: f6, path: "/tm/jjkj/rr_classroom",name:'数据画像',desc:'通过教辅发布作业' ,routerName:'rr_classroom'})
			  
			  this.jumpPic=arr
			  this.functionArr=arr2
			  return 
		  }
		  
		  queryOwnHiddenMenus({ }).then(res => {
		  	if (res.status == 0 && res.data) {
				
		  	let classroomResult = res.data.find(obj => obj.href === 'classroom')//智慧课堂
			let ahp_report = res.data.find(obj => obj.href === 'ahp_report')//综合测评
			
			let class_manager_class = res.data.find(obj => obj.href === 'class_manager_class')//班级管理
			let work_mark = res.data.find(obj => obj.href === 'work_mark')//在线批改（批改图片）
			let correct_handwriting = res.data.find(obj => obj.href === 'correct_handwriting')//在线批改（批改图片）
			let rr_classroom = res.data.find(obj => obj.href === 'rr_classroom')//数据画像（智慧课堂）
			let rr_ahp_report = res.data.find(obj => obj.href === 'rr_ahp_report')//数据画像（综合测评）
			
			console.log('__classroomResult:',classroomResult)
			console.log('work_mark:',work_mark)
			console.log('rr_classroom:',rr_classroom)
			console.log('rr_ahp_report:',rr_ahp_report)
			if(classroomResult){
				//arr.unshift({ src: home1, path: "/tm/jjkj/classroom" })
				arr.push({ src: home1, path: "/tm/jjkj/classroom",routerName:'classroom' })
			}
			
			if(ahp_report){
				//arr.unshift({ src: home1, path: "/tm/jjkj/classroom" })
				arr.push({ src: home2, path: "/tm/jjkj/ahp_report",routerName:'ahp_report' })
			}
			
			if(this.userInfo.isAdmin == 0){
				arr.push({ src:home4 , path: "/bkt/oc_manage/manage_prepare",routerName:'manage_prepare' })
			}else{
				queryOwnKbSetBTV2({ }).then(res => {
					if (res.status == 0)  {
						if(res.data.type == 3 || res.data.isManage == 0  || (res.data.organDomains && res.data.organDomains.length>0)){
							arr.push({ src:home4 , path: "/bkt/oc_manage/manage_prepare",routerName:'manage_prepare' })
						}
							
					}
				})
			}
			
			queryOwnBTHwCounts({ }).then(res => {
				if (!isNaN(Number(res.data)) && res.data > 0) {
						arr.push({ src: home3, path: "/bkt/open_course/prepare",routerName:'prepare' })
				}
			})
			/* 
			queryOwnKbSetBTV2({ }).then(res => {
				if (
				(res.status == 0 && res.data.type == 3 && res.data.isManage == 0)  || this.userInfo.isAdmin == 0) {
						arr.push({ src:home4 , path: "/bkt/oc_manage/manage_prepare",routerName:'manage_prepare' })
				}
				
				queryOwnBTHwCounts({ }).then(res => {
					if (!isNaN(Number(res.data)) && res.data > 0) {
							arr.push({ src: home3, path: "/bkt/open_course/prepare",routerName:'prepare' })
					}
				})
			})
			 */
			this.jumpPic=arr
			
			if(class_manager_class){
				arr2.push({ src: f3, path: "/tm/jjkj/class_manager_class",name:'班级管理',desc:'通过教辅发布作业' ,routerName:'class_manager_class'})
			}
			
			// if(work_mark){
			// 	arr2.push({ src: f5, path: "/tm/jjkj/work_mark",name:'在线批改',desc:'通过教辅发布作业' ,routerName:'work_mark'})
			// }
			if(correct_handwriting){
				arr2.push({ src: f5, path: "/jjzx/tm/correct/handwriting",name:'在线批改',desc:'通过教辅发布作业' ,routerName:'correct_handwriting'})
			}
			
			if(rr_classroom){
				arr2.push({ src: f6, path: "/tm/jjkj/rr_classroom",name:'数据画像',desc:'通过教辅发布作业' ,routerName:'rr_classroom'})
		  	}
			
			this.functionArr=arr2
			}
		  })
	  },
	
	  
	  logout() {
		logout({}).then(() => {
		  let organId = storage.get("lemon_organId");
		  let hWidth2 = storage.get("hWidth2");
		  // this.$store.dispatch("sidebar/clear");
		  // this.$store.dispatch("classroom/clear");
		  storage.clear();
		  // storage.set("lemon_organId" + this.userForm.mobile, organId);
		  // storage.set("hWidth2", hWidth2);
		  // this.$router.push({
		  //   path: "/",
		  // });
		  this.userInfo=null
		});
	  },
	  
	  _checkWsToken(){},
	  
	  _queryLoginUserByUserId(){
		  queryLoginUserByUserId({ }).then(res => {
		  	if (res.status == 0) {
		  			this.userInfo=res.data
					storage.set("lemon_userInfo",this.userInfo)
		  	}
			
			this._queryOwnHiddenMenus()
		  })
	  },
	  
	  test(){
	  		  console.log('123')
	  },
   
   changeOrgan(){},
   
   toHome(){},
   
   _queryImageVideos(){
	   queryImageVideos({ type: 51 ,noToken:true}).then(res => {
	   	if (res.status == 0) {
			this.banners=res.data
	   	}
	   })
   },
   
   changePath(path,routerName){
	   console.log('__changePath1:'+location.origin)
	   console.log('__path:'+path)
	   // let { href } = this.$router.resolve({
	   // 	path: path,
	   // 	query:{
	   	  
	   // 	}
	   // })
	   
	   //window.open(location.origin + "/tm/jjkj/classroom?v=" + new Date().getTime(), "_self");
	   
	   if(this.userInfo==null){
		   alert('登录后才可查看！')
	   }
	   addUserCache({
		 userCacheJson: {
		   routerName: routerName,
		   organId: this.userInfo.organId,
		 },
	   }).then(res => {
		    window.open(location.origin+path);
			if (res.status == 0) {
			}
	   }).finally(() => {
		   
		   });
	   
   },
   
   
  },
};
</script>

<style>
	
	.main{
		width: 1920px;
		height: 1734px;
		background: #F2F6FF;
	}
	
.top_frame{
	width: 100%;
	height: 106px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
}
	
.teacherHead {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-left: 40px;
  margin-right: 12px;
}

.to_home{
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 64px;
	height: 32px;
	background: #4880E1;
	border-radius: 16px;
	font-size: 12px;
	color: #FFFFFF;
	cursor: pointer;
}

.content_frame{
	width: 1784px;
	height: 1099px;
	background: #FFFFFF;
	border-radius: 10px;
	margin-left: 68px;
	margin-top: 42px;
}

.other_function{
	margin-left: 70px;
}

.other_function_title{
	margin-top: 54px;
	font-size: 33px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #000E18;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 42px;
}

.function_frame{
	/* width: 100%; */
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
}

.function_item{
	width: 274px;
	height: 144px;
	background: #F8F8F8;
	border-radius: 8px;
	margin-right: 68px;
	margin-bottom: 30px;
}

.function_item_top{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-left: 30px;
	margin-top: 27px;
}

.function_item_name{
	margin-left: 19px;
	font-size: 24px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #000E18;
}

.function_item_bottom{
	font-size: 20px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #ACACAC;
	margin-left: 30px;
	margin-top: 12px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

</style>
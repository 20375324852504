<template>
  <div>
    <UserAgreement :type='type'></UserAgreement>
  </div>
</template>

<script>
import UserAgreement from "../components/UserAgreement/inex.vue";
export default {
  name: "Agreement",
  created() {
      this.type = this.$route.query.type;
      console.log('this.type') ; 
      console.log(this.type) ;
  } ,
  data() {
    return {
        type: 0 ,
    };
  },
  components: { UserAgreement },
};
</script>
import {
	fetch
} from "./index"; //引用fetch.js


export function queryImageVideos(params) {
	return fetch(`/osmdb/imageVideoRest/queryImageVideos`, {
		method: 'post',
		data: params,
	})
}

export function queryLoginUserByUserId(params) {
	return fetch(`/osmdb/userRest/queryLoginUserByUserId`, {
		method: 'post',
		data: params,
	})
}

export function queryOwnHiddenMenus(params) {
	return fetch(`/osmdb/userOrganRest/queryOwnHiddenMenus`, {
		method: 'post',
		data: params,
	})
}

export function queryOwnBTHwCounts(params) {
	return fetch(`/qbms/userHwRest/queryOwnBTHwCounts`, {
		method: 'post',
		data: params,
	})
}

export function queryOwnKbSetBTV2(params) {
	return fetch(`/qbms/kbSetRest/queryOwnKbSetBTV2`, {
		method: 'post',
		data: params,
	})
}

export function addUserCache(params) {
  return fetch(`/osmdb/wsRest/addUserCache`, {
    method: "post",
    data: params,
  });
}
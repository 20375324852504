<template>
  <div>
    <div class="rich-text-box ql-editor">
      <div class="rich-text" v-if="type != 3">
        <p v-html="rich_text"></p>
      </div>

      <div v-else class="rich-text">
        <img
          src="@/assets/img/20210611165431.jpg"
          alt="logo"
          class="logo-img"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {queryOneAppAgreement} from "../../axios/api_tch.js";
import text_2 from "./rich-text_2.js";
export default {
  name: "UserAgreement", // 用户协议
  props: {
    type: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      rich_text: "",
      index: 0,
    };
  },
  watch: {
    type: {
      handler(val) {
            if (val == 2) {
          this.rich_text = text_2;
          return
        }
        let model={}
         if (val == 0||val == 1) {
       model.id="jjkjOfficial"
        }else  if (val == 4||val == 5) {
       model.id="jjzxTchmApp"
        }else  if (val == 6||val == 7) {
       model.id="cpaApp"
        }else  if (val == 8||val == 9) {
       model.id="hwApp"
        }
        queryOneAppAgreement(model).then(res=>{
            // console.log("val",val,res);
            if (val == 0||val == 6||val == 4||val == 8) {
       this.rich_text = res.data.agreement;
        }else  if (val == 1||val == 5||val == 7||val == 9) {
          this.rich_text = res.data.agreement2;
        }
        })
      
    //     if (val == 0) {
    //    model.id=""
    //     }
    //     if (val == 1) {
    //       this.rich_text = text_1;
    //     }
    
		// if (val == 4) {
		//   this.rich_text = text_4;
		// }
		// if (val == 5) {
		//   this.rich_text = text_5;
		// }
    // if (val == 6) {
		//   this.rich_text = text_6;
		// }
    // if (val == 7) {
		//   this.rich_text = text_7;
		// }
    //  if (val == 8) {
		//   this.rich_text = text_8;
		// }
    //  if (val == 9) {
		//   this.rich_text = text_9;
		// }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
@import url("../../assets/css/quill.core.css");
@import url("../../assets/css/quill.snow.css");
@import url("../../assets/css/quill.bubble.css");
.rich-text-box {
  padding-top: 50px;
  width: 100%;
  text-align: left;
}
.rich-text {
  max-width: 900px;
}
</style>
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="600 + 40 + 'px'"
  >
    <span v-if="error" class="colorError">验证码错误，请重试</span>
    <span v-else
      >请依次点击：<span
        v-for="item in words"
        :key="item"
        class="marginleft10"
        >{{ item }}</span
      ></span
    >
    <div
      :style="{ width: 600 + 'px', height: 300 + 'px', margin: '10px 0' }"
      v-loading="loading"
      element-loading-text="拼命加载中"
      class="relative"
    >
      <img
        id="picDom"
        v-if="url"
        :src="url"
        class="width100 height100"
        @load="imgLoad"
        @error="getOnePtvError"
        @click="piClick"
      />
      <div
        v-for="(item, index) in dots"
        :style="{
          left: item.left + 'px',
          top: item.top + 'px',
        }"
        class="dot flex-c-c font18 colorWhite"
        :key="index"
        @click="dotClick(index)"
      >
        {{ index + 1 }}
      </div>
    </div>
    <div class="width100 flex-b-c margintop10">
      <div
        style="width: 40px; height: 40px"
        class="flex-c-c cursor_pointer"
        @click="init(callBack)"
      >
        <i class="el-icon-refresh-right font18"></i>
      </div>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getOnePtv, checkPtvV2 } from "@/axios/login.js";
export default {
  data() {
    return {
      dialogVisible: false,
      title: "正在获取图形验证码......",
      words: [],
      url: "",
      loading: false,
      callBack: null,
      dots: [],
      id: "",
      error: false,
    };
  },
  methods: {
    init(callBack) {
      this.dialogVisible = true;
      this.loading = true;
      this.dots = [];
      getOnePtv({})
        .then((res) => {
          this.callBack = callBack;
          if (
            res.data &&
            res.data.id &&
            res.data.url &&
            res.data.words &&
            res.data.words.length
          ) {
            this.error = false;
            this.id = res.data.id;
            this.url = res.data.url;
            this.words = res.data.words;
            this.title = "安全验证";
          } else {
            this.getOnePtvError();
          }
        })
        .catch(() => {
          this.callBack = callBack;
          this.getOnePtvError();
        });
    },
    imgLoad() {
      this.loading = false;
    },
    getOnePtvError() {
      if (this.callBack) {
        this.$confirm("获取图形验证码失败，是否重新获取?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.init(this.callBack);
          })
          .catch(() => {
            this.dialogVisible = false;
            this.callBack({ ret: 0, errorCode: 1 });
            this.callBack=null
          });
      }
    },
    piClick(e) {
      let picDom = document.getElementById("picDom");
      if (picDom) {
        let picDomData = picDom.getBoundingClientRect();
        if (this.dots.length >= this.words.length) {
          this.dots = this.dots.slice(0, this.words.length - 1);
        }
        this.dots.push({
          left: e.clientX - picDomData.x - 15,
          top: e.clientY - picDomData.y - 15,
        });
      }
    },
    dotClick(index) {
      if (index > 0) {
        this.dots = this.dots.slice(0, index);
      } else {
        this.dots = [];
      }
    },
    submit() {
      if (this.dots.length < this.words.length) {
        this.checkError();
      } else {
        let ptvDomain = {
          id: this.id,
          ptvWordDomains: this.dots.map((el) => {
            return { x: el.left + 15, y: el.top + 15 };
          }),
        };
        checkPtvV2(ptvDomain).then((res) => {
          if (res.status === 0) {
            this.callBack({ ret: 0, ptvDomain });
            this.callBack=null
            this.dialogVisible = false;
          } else {
            this.checkError();
          }
        });
      }
    },
    checkError() {
      this.error = true;
      this.title = "正在获取图形验证码......";
      this.loading = true;
      setTimeout(() => {
        this.init(this.callBack);
      }, 1000);
    },
  },
};
</script>
<style scoped>
.dot {
  position: absolute;
  border-radius: 50%;
  background-color: #007dfa;
  border: 2.26667px solid rgb(255, 255, 255);
  cursor: pointer;
  width: 30px;
  height: 30px;
}
</style>
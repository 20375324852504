<template>
  <div class="join">

  <div style="background-color:rgba(247, 248, 250, 1);height:20px;width:100%;"></div>
    <div class="join-box">
      <div class="join-item" v-for="(item, index) in content" :key="index">
        <p class="join-item-title-1">{{ item.title_1 }}</p>
        <div class="join-item-introduction">
          <span class="join-item-introduction-1">{{
            item.introduction[0]
          }}</span>
          <span class="join-item-introduction-3">{{
            item.introduction[1]
          }}</span>
          <span class="join-item-introduction-2">{{
            item.introduction[2]
          }}</span>
        </div>

        <div class="join-item-details">
          <p class="join-item-title-2">{{ item.title_2 }}</p>
          <p
            class="join-item-row"
            v-for="(it, ind) in item.duty_row"
            :key="ind"
          >
            {{ it }}
          </p>
        </div>

        <div>
          <p class="join-item-title-2">{{ item.title_3 }}</p>
          <p
            class="join-item-row"
            v-for="(it, ind) in item.job_requirements"
            :key="ind"
          >
            {{ it }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      content: [
        {
          title_1: "Web前端开发工程师",
          introduction: ["全职", "|", "10-16K"],
          title_2: "岗位职责",
          duty_row: [
            "1.负责PC及移动端产品的前端技术选型与开发；",
            "2.利用 canvas等图像处理控件完成图像处理；",
            "3.负责对产品页面性能的优化和维护，持续提升用户体验，并保证兼容性和执行效率；",
            "4.配合U设计师进行页面设计、实现设计师绘制的页面",
            "5.与后端开发人员配合完成前后端接口的联调、功能实现与后期维护",
            "6.完成公司开发所需的重要技术方向的研究或攻关工作。",
          ],
          title_3: "岗位要求",
          job_requirements: [
            "1.3年及以上移动端和Web前端开发经验，能独立完成前端框架搭建、公共组件开发；",
            "2.精通HTML5、CSS3、 JAVASCRIPT、Ajax、Jquery、Canvas图形处理控件等相关技术",
            "3.精通Vue,能独立开发常用组件；",
            "4.熟悉 React Native调用原生插件，熟悉小程序开发经验者优先考虑；",
            "5.具备较强的分析和解决问题的能力，具备良好的沟通和抗压能力。",
          ],
        },
        {
          title_1: "安卓开发工程师",
          introduction: ["全职", "|", "10-15K"],
          title_2: "岗位职责",
          duty_row: [
            "1.负责Android软件产品的系统分析与设计工作，承担核心功能代码编写，开发与维护系统公用核心模块；",
            "2.按照项目计划按时提交高质量的代码完成开发任务；",
            "3.能及时配合后台联调接口并及时解决技术瓶颈性问题；",
            "4.负责修复功能缺陷、优化性能，提高应用运行的性能和体验；",
            "5.对代码的规范、API接口的编写、接口约束条件、代码维护性具有良好规范性；",
            "6.解决项目开发中的问题、问题跟踪以及反馈解决；",
            "7.研究新技术，满足产品的需求。",
          ],
          title_3: "岗位要求",
          job_requirements: [
            "1.统招本科或以上学历，计算机及理科相关专业；",
            "2.年龄26-36岁之间",
            "3.3年以上Android开发经验；",
            "4.熟悉软件迭代开发流程，理解敏捷软件过程；",
            "5.精通AS开发工具，熟练使用相关辅助工具，熟练各版本android SDK特性；",
            "6.熟练使用Android自定义控件、事件传递机制、Activity、Fragment、Service生命周期管理；",
            "7.精通Frame、Tween动画效果(旋转、缩放、渐变等)，精通Property属性动画的开发实现；",
            "8.熟练掌握UI设计常用布局、动画特效，熟悉控件触摸动作捕获（拖拽、移动、多点触摸等）；",
            "9.熟悉常见UI特效原理（下拉刷新等），UI绘制、View渲染策略，熟悉大量常用开源第三方库；",
            "10.熟练运用Androidjson/xml解析，HTTP/HTTPS协议、Android多线程操作，加密和Socket网络通信；",
            "11.熟悉Android框架及各种特性，熟练运用面向对象编程，至少深入理解5种以上常用设计模式，并且运用到项目中；",
            "12.熟悉Canvas等图形处理组件及OpenCV控件，能完成图片加载、优化、切割、存储、自定义View等；",
            "13.熟悉java语言，理解java各种常用方法原理",
            "14.有教育APP（或熟悉NDK开发，熟悉JNI、binder等相关技术开发经验）开发经验者优先录用；",
            "15.工作积极主动有强烈责任感和严谨的工作作风，较强执行力和沟通能力对移动产品浓厚兴趣；",
            "16.熟悉React-Native混合开发技术、有IOS/Web前段开发经验者优先。",
          ],
        },

        {
          title_1: "IOS开发工程师",
          introduction: ["全职", "|", "8-13K"],
          title_2: "岗位职责",
          duty_row: [
            "1.负责APP各项业务功能的开发和维护；",
            "2.负责APP基础组件、框架等方面的设计、开发和维护；",
            "3.参与技术选型，探索新技术在业务中的落地；",
            "4.参与团队工作流程、方法与工具的建设与改进。",
            "5.独立完成APP需求开发。",
          ],
          title_3: "岗位要求",
          job_requirements: [
            "1.计算机科学、软件工程或相关专业统招本科及以上学历；",
            "2.年龄在26-36岁之间",
            "3.熟练使用OC、Swift独立完成APP的功能开发；",
            "4.熟悉Canvas或同类组件及Opencv等，能完成图片加载、优化、切割、存储、自定义View等；",
            "5.熟悉React-Native混合开发技术；",
            "6.基础良好，对计算机系统、数据结构和算法有一定的认知；",
            "7.熟悉软件迭代开发流程，理解敏捷软件过程；",
            "8.熟悉平台生态，及时关注业界的最新技术，有强烈的求知欲；",
            "9.积极乐观，责任心强，工作细致认真，具有良好的团队功能与协作能力，能承担一定压力；",
            "10.对OOP、代码质量保证、代码复用等有一定自己的理解；",
            "11.有Android、Web前端开发经验者优先。",
          ],
        },

        {
          title_1: "化学编审/编辑",
          introduction: ["全职", "|", "4-8K"],
          title_2: "岗位职责",
          duty_row: [
            "1.遵守公司规章制度，按时保质地完成工作任务",
            "2.负责稿件的审核与加工整理；",
            "3.保证稿件内容、版式、文字、逻辑的正确；",
            "4.题库资源的在线编辑加工工作。",
          ],
          title_3: "岗位要求",
          job_requirements: [
            "1.具备扎实的初中化学学科专业基础知识，化学专业优先；",
            "2.具有多年编审经历或教学经历，拥有初高中教师资格证或初中级编辑证者优先录用。",
            "3.具有较强的责任心、耐心与细心，时间意识和团队合作精神较强。",
          ],
        },

        {
          title_1: "物理编辑",
          introduction: ["全职", "|", "4-8K"],
          title_2: "岗位职责",
          duty_row: [
            "1.遵守公司规章制度，按时保质地完成工作任务",
            "2.负责稿件的审核与加工整理；",
            "3.保证稿件内容、版式、文字、逻辑的正确；",
            "4.题库资源的在线编辑加工工作。",
          ],
          title_3: "岗位要求",
          job_requirements: [
            "1.具备扎实的初中化学学科专业基础知识，化学专业优先；",
            "2.具有多年编审经历或教学经历，拥有初高中教师资格证或初中级编辑证者优先录用。",
            "3.具有较强的责任心、耐心与细心，时间意识和团队合作精神较强。",
          ],
        },

        {
          title_1: "数学编审/编辑",
          introduction: ["全职", "|", "4-8K"],
          title_2: "岗位职责",
          duty_row: [
            "1.遵守公司规章制度，按时保质地完成工作任务",
            "2.负责稿件的审核与加工整理；",
            "3.保证稿件内容、版式、文字、逻辑的正确；",
            "4.题库资源的在线编辑加工工作。",
          ],
          title_3: "岗位要求",
          job_requirements: [
            "1.具备扎实的初中化学学科专业基础知识，化学专业优先；",
            "2.具有多年编审经历或教学经历，拥有初高中教师资格证或初中级编辑证者优先录用。",
            "3.具有较强的责任心、耐心与细心，时间意识和团队合作精神较强。",
          ],
        },

        {
          title_1: "数学教研老师",
          introduction: ["兼职/全职", "|", "3.5-9K"],
          title_2: "岗位职责",
          duty_row: [
            "1.以公司免费提供的产品开展“大数据精准教学智能作业的校本化”实践研究;",
            "2.参与国家级课题研究，负责提供案例、数据支撑等；",
            "3.协助研究初中数理化学科知识点图谱，抽查本公司教辅题库中习题知识点标定的准确性等工作；",
            "4.结合实践运用，给产品开发提供合理化建议；",
            "5.每月来公司上班3天以上。",
          ],
          title_3: "岗位要求",
          job_requirements: [
            "1.初中数学一线在职教师；",
            "2.5年及以上教学经验；",
            "3.在相应领域有感悟、有方法，不满足现状，锐意进取者优先；",
            "4.统招本科以上学历，985、211优先；",
            "5.名师工作室负责人、教研学科组长和各种优秀教师称号的优先。",
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.join-item {
  padding-bottom: 55px;
  width: 100%;
  border-bottom: 2px dashed #acacac;
  margin-bottom: 50px;
}
.join-item-row {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #000e18;
  letter-spacing: 1px;
  line-height: 34px;
  font-weight: 400;
}
.join-item-title-2 {
  margin-top: 24px;
  margin-bottom: 10px;
  font-family: PingFangSC-Medium;
  font-size: 20px;
  color: #000e18;
  letter-spacing: 1.25px;
  font-weight: 500;
}
.join-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 1920px;
}
.join-item-introduction-2 {
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  color: #df0000;
  letter-spacing: 1.25px;
  font-weight: 600;
}
.join-item-introduction-3 {
  color: #979797;
  margin: 0px 20px;
}
.join-item-introduction-1 {
  font-family: PingFangSC-Regular;
  font-size: 20px;
  color: #666666;
  letter-spacing: 1.25px;
  font-weight: 400;
}
.join-item-introduction {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
  widows: 100%;
}
.join-item-title-1 {
  font-family: PingFangSC-Medium;
  font-size: 24px;
  color: #000e18;
  letter-spacing: 1.5px;
  font-weight: 500;
}
.join-box {
  background-color: #fff;
  margin: 0px 200px 20px 200px;
  padding: 40px;
}
p {
  text-align: left !important;
}

@media screen and (max-width: 1024px) {

  .join-box{
    margin: auto;
  }
  .join-item{
    padding-bottom: 40px;
    margin-bottom:40px ;
  }

}
</style>

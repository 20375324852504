<template>
  <div class="container">
    <div
      style="
        background-color: rgba(247, 248, 250, 1);
        height: 20px;
        width: 100%;
      "
    ></div>
    <div class="content">
      <div class="tabBox">
        <div
          class="tabPane"
          :class="activeName == 'app' ? 'act' : ''"
          @click="activeName = 'app'"
        >
          App
        </div>
        <div
          class="tabPane"
          :class="activeName == '小程序' ? 'act' : ''"
          @click="activeName = '小程序'"
        >
          小程序
        </div>
      </div>
      <div class="tabContent" v-show="activeName == 'app'">
        <div
          class="item"
          @click="downloadfn(item)"
          v-for="(item, index) in applist"
          :key="index"
        >
          <img class="logo" :src="item.src" :alt="item.title" />
          <div class="text">{{ item.title }}</div>
          <button>
            <img class="download" :src="downloadImg" :alt="item.title" />
            下载
          </button>
        </div>
        <div>
          {{ dataString }}
        </div>
      </div>
      <div class="tabContent" v-show="activeName == '小程序'"></div>
    </div>
  </div>
</template>

<script>
import pmimg from "@/assets/img/xueshengduan.png";
import pmteacherimg from "@/assets/img/jiaoshiduan.png";
import cpaimg from "@/assets/img/zicipinyin.png";
import downloadImg from "@/assets/img/xiazai.png";
export default {
  name: "DownLoad",
  data() {
    return {
      dataString: "",
      downloadImg: downloadImg,
      publicPath: process.env.BASE_URL,
      activeName: "app",
      applist: [
        {
          title: "精教智学学生",
          src: pmimg,
          appid: "com.jzjyt.app.pm",
          other: "https://a.app.qq.com/o/simple.jsp?pkgname=com.jzjyt.app.pm",
        },
        {
          title: "精教智学老师",
          src: pmteacherimg,
          appid: "com.jzjyt.app.pmteacher",
          other:
            "https://a.app.qq.com/o/simple.jsp?pkgname=com.jzjyt.app.pmteacher",
        },
        {
          title: "字词拼音",
          src: cpaimg,
          appid: "com.jjzxapp.app.cpa",
          other: "",
        },
      ],
    };
  },
  methods: {
    downloadfn(item) {
      let sUserAgent = navigator.userAgent.toLowerCase();
      this.dataString = 222 + sUserAgent;
      var isHuawei = sUserAgent.match(/huawei/i) == "huawei";
      var isHonor = sUserAgent.match(/honor/i) == "honor";
      var isOppo = sUserAgent.match(/oppo/i) == "oppo";
      var isOppoR15 = sUserAgent.match(/pacm00/i) == "pacm00";
      var isOppoK7x = sUserAgent.match(/perm00/i) == "perm00";
      var isVivo = sUserAgent.match(/vivo/i) == "vivo";
      var isXiaomi = sUserAgent.match(/mi\s/i) == "mi ";
      var isXiaomi2s = sUserAgent.match(/mix\s/i) == "mix ";
      var isRedmi = sUserAgent.match(/redmi/i) == "redmi";

      if (isHuawei || isHonor) {
        location.href = "appmarket://details?id=" + item.appid;

        this.dataString += item.appid;
      } else if (isOppo || isOppoR15 || isOppoK7x) {
        location.href = "oppomarket://details?packagename=" + item.appid;
        this.dataString += item.appid;
      } else if (isVivo) {
        location.href = "vivomarket://details?id=" + item.appid;
        this.dataString += item.appid;
      } else if (isXiaomi || isRedmi || isXiaomi2s) {
        location.href = "mimarket://details?id=" + item.appid;
        this.dataString += item.appid;
      } else {
        location.href = item.other;
        this.dataString += item.other;
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
}
.content {
  background-color: #fff;
  flex: 1;
}
.tabBox {
  height: 40px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: flex-start;
}
.tabPane {
  margin: 0 22px;
  width: 48px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  position: relative;
  font-weight: 500;
  color: #666666;
}
.tabPane.act {
  font-weight: 500;
  color: #000e18;
}
.tabPane.act::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -2.5px;
  width: 32px;
  margin-left: -16px;
  border-bottom: 5px solid #0096ff;
  border-radius: 3px;
}
.tabContent {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 36px 0;
}
.tabContent .item {
  position: relative;
  width: 27%;
  min-width: 101px;
  margin-left: 14%;
  margin-right: 2%;
  margin-bottom: 49px;
}
.tabContent .item .logo {
  width: 100%;
}
.tabContent .item .text {
  width: 100%;
  height: 50px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000e18;
  line-height: 50px;
}
.tabContent .item button {
  border-radius: 5px;
  width: 100%;
  height: 37px;
  background-color: #ffffff;
  color: #0096ff;
  border: 1px solid #0096ff;
}
.tabContent .item .download {
  width: 15px;
}
</style>
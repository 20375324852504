let text_1 = `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=gb2312">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:宋体;
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:等线;
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:"\@宋体";
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:"\@等线";
	panose-1:2 1 6 0 3 1 1 1 1 1;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{
	  padding:0 10pt;
	margin:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	font-size:10.5pt;
	font-family:等线;}
.MsoChpDefault
	{font-family:等线;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:72.0pt 90.0pt 72.0pt 90.0pt;
	layout-grid:15.6pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=ZH-CN link="#0563C1" vlink="#954F72" style='word-wrap:break-word;
text-justify-trim:punctuation'>

<div class=WordSection1 style='layout-grid:15.6pt'>

<p class=MsoNormal align=center style='text-align:center;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>《精教智学儿童用户隐私政策》</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>山东精准教育科技发展有限公司（下称<span
lang=EN-US> “</span>公司<span lang=EN-US>”</span>）深知保护儿童个人信息的重要性，公司致力于保护儿童的隐私和信息保护，遵守法律法规，特制定《精教智学儿童用户隐私政策》。本政策旨在向法定监护人或儿童的父母（以下简称“监护人”）和儿童说明公司如何收集、使用、存储和处理儿童的个人信息，以及控制儿童个人信息的方式。请监护人仔细阅读并向儿童解释以下条款，特别提醒监护人应仔细阅读本《精教智学儿童用户隐私政策》的条款内容，确认同意全部条款后再开始使用。如果监护人不同意本《精教智学儿童用户隐私政策》，儿童将可能无法正常使用公司的产品、服务。本政策正文中的<span
lang=EN-US>“</span>您<span lang=EN-US>”</span>指儿童。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>监护人特别说明：请监护人仔细阅读和选择是否同意本《精教智学儿童用户隐私政策》，公司希望监护人与公司共同保护儿童的个人信息，教育、引导儿童增强个人信息保护意识和能力，指导、提醒和要求他们在未经监护人同意的情况下，不向任何精教智学产品和服务提供者提供任何个人信息。如您不同意本隐私政策，您应要求您的孩子立即停止使用精教智学的服务，并拒绝提供儿童个人信息。您于线上点击同意本政策，或者您的孩子使用<span
lang=EN-US>/</span>继续使用精教智学服务、提交个人信息，即表示您已同意公司按照本隐私政策来收集、使用、存储和保护您孩子相关的个人信息。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>儿童特别说明：儿童在使用精教智学产品和服务或提供任何个人信息前，请和监护人一起仔细阅读本《精教智学儿童用户隐私政策》，并在征得监护人同意后，使用公司的产品、服务或向公司提供信息。您于线上点击同意本政策，或者您使用<span
lang=EN-US>/</span>继续使用精教智学服务、提交个人信息，即表示您已获得您的监护人的许可，您的监护人已同意公司按照本隐私政策来收集、使用、存储和保护您相关的个人信息。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>公司根据您填写年级或您提供的其他信息判断您的儿童身份，公司理解儿童信息属于个人敏感信息，公司根据国家相关法律法规的规定，保护儿童的个人信息，只会在法律法规允许、监护人明确同意的情况下收集、存储、使用、转移、披露您的信息，并遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则。对于未经监护人同意而儿童用户提供的个人信息，在接到其监护人的通知后，公司会及时删除相关信息。本隐私政策将帮您了解以下内容：</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>一、适用范围</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>1.</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>本隐私政策适用于除某些特定产品和服务外精教智学提供的所有服务。这些特定产品和服务将适用特定的隐私政策。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>二、公司如何收集和使用您的个人信息</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。<span
lang=EN-US>14</span>岁以下（含）儿童的个人信息属于个人敏感信息。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>公司提供的产品和服务需要依赖您的部分信息才得以运行，因此在您使用精教智学的时候，需要向公司提供或允许公司收集的必要信息，以供公司使用这些信息向您提供基本服务及更优更好的个性化服务，包括：</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>1.</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>儿童经监护人的同意后主动提供给公司的信息</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>1</span>）帮助您成为公司的用户，您填写的注册信息，包括您电话号码，以用于注册、登录精教智学账户使用精教智学产品和服务，及公司的客服和售后为您提供咨询服务，并保障您的账户安全。如不提供，您将仅能浏览、了解精教智学，无法享受相关服务；如果您仅需使用浏览、了解精教智学，您不需要注册成为公司的用户及提供上述信息；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>2</span>）便于公司向您提供服务，您填写的个人资料，包括您的昵称、性别、身份、年级、学校、入学年份，以用于查询或推荐内容、加入群组。您可以根据是否需要个性化服务选择是否提供上述信息，如不提供，不会影响您使用本产品的其他功能和服务；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>3</span>）您在精教智学购买需要邮寄的产品或者参加公司有关的市场营销活动时，您提供的姓名、通信地址、联系方式；以便于您接收实物产品、礼品，如果您拒绝提供这些信息，公司无法向您寄送您购买的实物产品、礼品，不会影响您使用本产品的其他功能和服务；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>4</span>）当您与公司联系时，公司可能会保存您的通信或通话记录和内容或您留下的联系方式，以便帮助您解决问题，或记录相关问题的处理方案及结果。公司的客服会使用您的帐号信息与您核验您的身份。您有可能会在与公司的客服人员沟通时，提供给出上述信息外的其他信息。如不提供，不会影响您使用本产品的其他功能和服务。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>2</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>公司在您使用服务过程中收集的信息，除法律规定情形外公司都将事先征得您监护人的同意：</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>1</span>）在您首次使用精教智学<span
lang=EN-US>APP</span>时，公司将在取得您的授权后，接收并记录您所使用的设备相关信息，包括设备属性信息（<span lang=EN-US>ME</span>、<span
lang=EN-US>IMS</span>、设备型号、操作系统版本、设备设置、唯一设备标识符、设备蓝牙信息、<span
lang=EN-US>Mac</span>地址）设备连接信息<span lang=EN-US>IP</span>地址、电信运营商、访问日期和时间）以保障您的账户及交易安全；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>2</span>）在您首次使用精教智学<span
lang=EN-US>APP</span>时，公司将在您授权后读取您的读取手机状态，用以保障精教智学<span lang=EN-US>APP</span>应用服务的正常使用，更准确定位和解决在使用时遇到的问题，改进及优化精教智学<span
lang=EN-US>APP</span>服务体验，及保障您的账号安全；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>3</span>）在您首次使用精教智学<span
lang=EN-US>APP</span>、下载图片、书本、错题、投稿上传图片、编辑个人资料上传头像（根据您的设备系统不同可能会有场景差异）时，公司将申请您的存储和相机；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>4</span>）在您首次打开精教智学<span
lang=EN-US>APP</span>时，公司将读取您的应用安装列表，以为您提供更准确的搜索结果；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>5</span>）在您首次使用资料库功能时，公司将申请您的地理位置权限，以提供搜索和推荐附近学校和本地区学情数据、错题试卷、练习题资料、知识点学案等服务；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>6</span>）在您首次使用扫条形码搜索、整本上传、上传解题心得、图片上传、投稿上传图片、编辑个人资料头像调取拍摄时功能时，公司将申请获取您的相机权限，以用于拍照上传服务；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>7</span>）在您首次使用精教智学<span
lang=EN-US>APP</span>时，公司将申请您的电话权限，以用于手机号一键登录；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>8</span>）在您首次打开精教智学<span
lang=EN-US>APP</span>时，公司将读取您的剪切板信息，用于识别拉新用户的运营活动。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>3.</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>事先征得同意的例外：</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>1</span>）与个人信息控制者履行法律法规规定的义务相关的；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>2</span>）与国家安全、国防安全直接相关的；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>3</span>）与公共安全、公共卫生、重大公共利益直接相关的；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>4</span>）与刑事侦查、起诉、审判和判决执行等直接相关的；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>5</span>）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>6</span>）所涉及的个人信息是个人信息主体自行向社会公众公开的；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>7</span>）根据个人信息主体要求签订和履行合同所必需的；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>8</span>）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>9</span>）维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>10</span>）个人信息控制者为新闻单位，且其开展合法的新闻报道所必需的。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>4.</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>匿名化处理对于公司收集到的您的信息</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>公司将通过技术手段及时进行匿名化处理。在不泄露您个人信息的前提下，公司有权对匿名化处理后的用户数据进行分析、挖掘和利用，有权对精教智学产品的使用情况进行统计分析并用于可能的第三方信息共享。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>5.</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>个性化服务</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>公司将根据您的浏览、使用记录、填写的信息，提取您的偏好特征，也可能将您的信息结合起来，进行数据分析，在如下场景为您提供个性化服务：</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>首页<span lang=EN-US>feed</span>流<span
lang=EN-US>-</span>用户标签推荐</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>个性化错题推荐</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>个性化错点学案</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>详情页<span lang=EN-US>-</span>猜你喜欢</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>求资料<span lang=EN-US>-</span>好友列表</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>三、公司如何使用<span
lang=EN-US>Cookie</span>和同类技术</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>公司或公司的第三方合作伙伴，可能通过<span
lang=EN-US>cookies</span>和<span lang=EN-US>webbeacon</span>收集和使用您的信息，并将该等信息储存为日志信息。公司使用自己的<span
lang=EN-US>cookies</span>和<span lang=EN-US>web beacon</span>，目的是为您提供更个性化的用户体验和服务，并用于以下用途：</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>1.</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>记住您的身份。例如：<span
lang=EN-US>cookies</span>和<span lang=EN-US>web beacon</span>有助于公司辨认您作为公司的注册用户的身份，或保存您向公司提供的有关您的喜好或其他信息。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>2.</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>分析您使用公司服务的情况。例如，公司可利用<span
lang=EN-US> cookies</span>和<span lang=EN-US> web beacon</span>来了解您使用公司的服务进行什么活动，或哪些服务最受您的欢迎。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>公司为上述目的使用<span
lang=EN-US>cookies</span>和<span lang=EN-US>web beacon</span>的同时，可能将通过<span
lang=EN-US>cookies</span>和<span lang=EN-US>web beacon</span>收集的非个人身份信息，经统计加工后提供给其他合作伙伴，用于分析用户如何使用公司的服务。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>公司的产品和服务上可能会有其他合作方放置的<span
lang=EN-US>cookies</span>和<span lang=EN-US>web beacon</span>。这些<span
lang=EN-US>cookies</span>和<span lang=EN-US>web beacon</span>可能会收集与您相关的非个人身份信息，以用于分析您如何使用该等产品或服务。这些第三方<span
lang=EN-US> cookies</span>和<span lang=EN-US>web beacon</span>收集和使用该等信息，不受本隐私政策约束，而是受相关第三方的隐私政策约束，公司不对此类<span
lang=EN-US>cookies</span>和<span lang=EN-US>web beacon</span>承担责任。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>您可以通过浏览器设置拒绝或管理<span
lang=EN-US>cookies</span>。但请注意，如果停用<span lang=EN-US>cookies</span>或<span
lang=EN-US>web beacon</span>，您有可能无法享受最佳的服务体验，某些产品或服务可能也无法正常使用。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>四、公司如何共享、转让、公开披露您的个人信息</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>1.</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>公司会以高度的勤勉义务对待您的信息，除非获得您监护人的明确同意，公司不会与本公司以外的任何公司、组织和个人分享您的个人信息，但经过处理无法识别特定个人且不能复原的除外。目前，公司会在以下情形中，向您征求您监护人对共享个人信息的授权同意：</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>1</span>）精教智学<span
lang=EN-US>APP</span>集成阿里云计算有限公司的阿里云号码认证<span lang=EN-US>SDK</span>，通过获取运营商中移互联网有限公司、中国联合网络通信集团有限公司、中国电信集团有限公司的<span
lang=EN-US> appid</span>信息，为您提供一键登录验证、号码认证服务《中国移动认证服务协议》、《联通服务条款和隐私政策》、《天翼账号隐私政策》；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>2</span>）精教智学<span
lang=EN-US>APP</span>集成高德软件有限公司的高德地图<span lang=EN-US>SDK</span>定位，高德地图<span
lang=EN-US>SDK</span>定位将收集您的网络状态、定位信息、读取写入外部储存，用于向您推荐附近用户的笔记、教辅、试卷资料，具体请见《高德地图开放平台产品和服务隐私政策》；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>3</span>）精教智学<span
lang=EN-US>APP</span>集成阿里云计算有限公司的阿里云反馈<span lang=EN-US>SDK</span>，阿里云反馈<span
lang=EN-US>SDK</span>将收集您的手机状态、网络状态、读取外部存储、<span lang=EN-US>IMS</span>设备信息，用于您反馈问题时的手机、网络状态给出诊断建议，读取外部存储截图提供反馈问题详情，具体请见阿里云《法律声明和隐私权政策》；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>4</span>）精教智学<span
lang=EN-US>APP</span>集成支付宝（中国）网络技术有限公司的支付宝<span lang=EN-US>SDK</span>，支付宝<span
lang=EN-US>SDK</span>将收集您的手机状态、网络状态、读取写入外部储存，用于处理和缓存您的支付订单及支付状态，具体请见《支付宝隐私权政策》；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>5</span>）精教智学<span
lang=EN-US>APP</span>集成深圳市腾讯计算机系统有限公司的腾讯验证码<span lang=EN-US>SDK</span>，腾讯验证码<span
lang=EN-US>SDK</span>将收集您的网络、访问有关网络的信息、访问有关<span lang=EN-US>WiFi</span>网络的信息，用于防止您的数据被恶意抓取，具体请见《腾讯验证码接入平台》；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>6</span>）精教智学<span
lang=EN-US>APP</span>集成深圳市腾讯计算机系统有限公司的微信开放平台<span lang=EN-US>SDK</span>，微信开放平台<span
lang=EN-US>SDK</span>收集您的设备标识信息，用于支持微信授权登录、微信分享您感兴趣的内容和微信支付，具体请见《微信开放平台》；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>7</span>）精教智学<span
lang=EN-US>APP</span>集成深圳市腾讯计算机系统有限公司的<span lang=EN-US>QQ</span>分享<span
lang=EN-US>SDK,QQ</span>分享<span lang=EN-US>SDK</span>收集您的设备标识信息，用于将您感兴趣的內容分享至<span
lang=EN-US>QQ</span>、<span lang=EN-US>QQ</span>空间，具体请见《腾讯开放平台》；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>公司仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息。对公司与之共享个人信息的公司、组织和个人，公司会与其签署严格的保密协定，要求他们按照公司的说明、本政策以及其他任何相关的保密和安全措施来处理信息，信息接收方对您的信息仅有有限的访问权，他们还需承担合同义务，包括不限于使用合法合规的方式获取您的信息，采取合理的安全措施保护您的信息，根据法律规定及与公司的约定承担相应的法律责任。对于一些开源、免费的合作方如果出现泄露您的信息等侵犯您个人权益的行为，公司承担相应的责任。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>2.</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>公司不会非法出售、非法向他人提供您的信息；公司不会将您的该等信息提供给任何第三方，也不会用于任何其他目的和用途，但是以下情形除外：</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>1</span>）经您监护人事先同意，向第三方披露</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>2</span>）根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>3</span>）在公司提供服务时，可能将您的信息用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，以预防、发现、调查欺诈、危害安全、非法或违反与公司的协议、政策或规则的行为，以保护您、其他用户、公司的关联公司、合作伙伴及其他受信任的第三方供应商、服务商、代理商及社会公众的合法权益，以确保公司向您提供的产品和服务的安全性；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>4</span>）公司与第三方合作向您提供精教智学平台的相关服务或其他服务，且该等第三方同意承担与公司同等的保护用户隐私的责任；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>5</span>）其他根据法律、法规或者政策应进行的披露。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>3.</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>公司仅会在以下情形下，公开披露您的个人信息：</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>1</span>）获得您监护人明确同意后；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>2</span>）基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，公司可能会公开披露您的个人信。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>4.</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>共享、转让、公开披露个人信息时事先征得授权同意的例外：</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>1</span>）与个人信息控制者履行法律法规规定的义务相关的；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>2</span>）与国家安全、国防安全直接相关的；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>3</span>）与公共安全、公共卫生、重大公共利益直接相关的；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>4</span>）与刑事侦查、起诉、审判和判决执行等直接相关的；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>5</span>）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>6</span>）个人信息主体自行向社会公众公开的个人信息；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>7</span>）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>5</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>．根据法律规定，共享、转让、披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理无需另行征得您监护人的同意。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>五、您监护人如何管理自己的信息及账号注销按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，公司保障您对自己的个人信息行使以下权利</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>1</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>．查询权</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>您有权查询您的个人信息，法律法规规定的例外情形除外。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>您可以通过<span lang=EN-US>“</span>精教智学我的<span
lang=EN-US>-</span>个人信息<span lang=EN-US>-</span>编辑资料<span lang=EN-US>”</span>查询或编辑您的账户中的个人资料信息、修改您的密码、快递地址信息。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>2</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>更正权</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>当您发现公司处理关于您的个人信息有错误时，您可以通过精教智学<span
lang=EN-US>-</span>我的<span lang=EN-US>-</span>个人信息<span lang=EN-US>-</span>编辑资料<span
lang=EN-US>”</span>查询到您的信息进行修改。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>3</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>．删除权</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>以下情形下，您可以向公司提出删除个人信息的请求：</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>1</span>）如果公司处理个人信息的行为违反法律法规；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>2</span>）如果公司收集、使用您的个人信息，却未征得您的同意；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>3</span>）如果公司处理个人信息的行为违反了与您的约定；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>4</span>）如果您不再使用公司的产品或服务，或您注销了账号；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>5</span>）如果公司不再为您提供产品或服务。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>当您决定删除某项或某几项信息时，您可以联系客服进行删除，发送邮件至<span
lang=EN-US>517770816@qq.com</span>。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>4</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>．撤销权</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>对于您个人主动提供的信息，您可以通过发送邮件至<span
lang=EN-US>517770816@qq.com</span>进行撤销；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>当您撤回同意或授权后，公司无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>5</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>．注销权</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>如果您决定停止使用精教智学的，则您可以对您的账号进行注销。账号注销可以在<span
lang=EN-US>“</span>精教智学<span lang=EN-US>-</span>我的<span lang=EN-US>-</span>设置<span
lang=EN-US>-</span>账号与安全<span lang=EN-US>-</span>注销账号”中实现。需要人工处理的，公司将在<span
lang=EN-US>15</span>个工作日内完成核查和处理。在注销账户之后，公司将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。注销账号之后此账号内的数据无法恢复，账号注销后将清除账号内的所有奖励、等级内容，同时在之后无法找回；除法律法规另有规定的情况之外，你的个人信息将被删除或匿名化处理，建议你做好备份。注销后手机号及第三方登陆账号将自动解除绑定，之后可以与新的精教智学账号绑定。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>6</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>．个人信息主体获取个人信息副本</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>您有权获取您的个人信息副本，您可以通过查询路径查询后执行此操作。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>在技术可行的前提下，如数据接口已匹配，公司还可按您的要求，直接将您的个人信息副本传输给您指定的第三方。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>7</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>．响应您的上述请求</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。公司可能会先要求您验证自己的身份，然后再处理您的请求。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>公司将在<span lang=EN-US>15</span>个工作日內或法律法规规定的期限内作出答复。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>如您不满意，还可以通过以下途径投诉：发送邮件至<span
lang=EN-US>517770816@qq.com</span>。对于您合理的请求，公司原则上不收取费用，但对多次重复、超岀合理限度的请求，公司将视情收取一定成本费用。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，公司可能会予以拒绝。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>在以下情形中，按照法律法规的要求，公司将无法响应您的上述请求：</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>1</span>）与个人信息控制者履行法律法规规定的义务相关的；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>2</span>）与国家安全、国防安全直接相关的；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>3</span>）与公共安全、公共卫生、重大公共利益直接相关的；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>4</span>）与刑事侦查、起诉、审判和执行判决等直接相关的</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>5</span>）个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>6</span>）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>7</span>）响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>（<span lang=EN-US>8</span>）涉及商业秘密的。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>六、公司如何存储您的个人信息</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>公司在中华人民共和国境内运营中收集和产生的个人信息，原则上存储在中国境內，并按照中国中华人民共和国法律法规、部门规章等规定的存储期限存储您的个人信息，存储期限为您使用精教智学的期限或者向精教智学发出删除您的个人信息之前，当您要求删除该存储信息或者决定停止使用精教智学注销账号之时，公司将及时删除您的个人信息。同时只会在达成本隐私政策所述目的所需的期限内保留您的个人信息，除非法律有强制的存留要求。例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年；就服务所涉及的用户行为日志，公司将进行匿名化处理。判断前述期限的标准包括：（<span
lang=EN-US>1</span>）完成与您相关的交易目的、维护相应交易及业务记录、应对您可能的查询或投诉（<span lang=EN-US>2</span>）保证平台为您提供服务的安全和质量；（<span
lang=EN-US>3</span>）您是否同意更长的留存期间；（<span lang=EN-US>4</span>）是否存在保留期限的其他特别约定。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>在您的个人信息超出保留期间后，公司会根据适用法律的要求删除您的个人信息，或使其匿名化处理（如您为未成年人，在您的个人信息超出保留期间后，公司会按照相关法律法规要求对您的个人信息进行相应处理）。如因特殊情形无法按照相关要求删除的，公司将对该部分事项向您进行说明。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>七、公司如何保护您的个人信息</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>1.</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>公司非常重视您的个人信息安全。公司努力采取各种合理的物理、电子和管理方面的安全措施来保护您的个人信息，并尽最大合理努力使您的个人信息不会被泄漏、毁损或者丢失，包括但不限于<span
lang=EN-US>SSL</span>、信息加密存储、数据中心的访问控制。公司对可能接触到您个人信息的员工或外包人员也采取了严格管理，包括但不限于采取信息访问权限控制、与接触个人信息的人员签署保密协议、监控该等人员的操作情况等措施。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>2</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>尽管有上述第<span
lang=EN-US>1</span>款的约定，但是请您理解并明白，由于存在各种各样无法预知或现有技术无法防御的恶意手段，即便公司已经尽力釆取必要的保护措施，您的信息仍有可能被泄露、毁损或灭失。如您发现您的账号、密码因任何原因已经或者将要泄露时，您应当立即跟公司联系，以便公司可以及时采取应对措施防止或减少您的相关损失。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>3.</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>如果公司发生企业合并、分立等情形的，则您的信息将由届时存续的企业主体继续履行保护义务，并由公司以公告、短信或其他书面方式通知您；如果公司的产品、服务停止运营的，则公司将及时停止继续收集您的信息，并以公告、短信等方式通知您，届时公司将对您的信息进行删除或隐匿化处理。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>4.</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>若不幸发生儿童个人信息安全事件，公司会启动应急预案，阻止安全事件扩大，并及时告知监护人和儿童：安全事件的基本情况和可能的影响、公司已采取或将要采取的处置措施、监护人和儿童可自主防范或降低风险的建议、对监护人和儿童的补救措施等。事件相关情况公司将以邮件、信函、电话、推送通知等方式告知监护人和儿童，难以逐一告知儿童个人信息主体时，公司会采取合理、有效的方式发布公告。同时，公司还将按照监管部门要求，上报儿童个人信息安全事件的处置情况。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>八、本政策的修订公司可不定期修改本隐私政策，变更后的隐私政策将在修订</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>生效前通过精教智学平台公告或以其他适当方式通知您监护人。该等情况下，若您继续使用公司的服务，即表示同意受经修订的隐私政策的约束。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>九、争议管辖</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>1</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>．本隐私政策的签订、生效、履行、争议的解决均适用中华人民共和国法律。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
lang=EN-US style='font-size:14.0pt;line-height:150%;font-family:宋体'>2.</span><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>有关本协议的争议应通过友好协商解决，如果协商不成，该争议将提交公司所在地有管辖权的法院诉讼解决。</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>十、联系公司</span></p>

<p class=MsoNormal style='text-indent:21.35pt;line-height:150%'><span
style='font-size:14.0pt;line-height:150%;font-family:宋体'>如您有任何疑问或投诉、举报，您可以通过下述方式联系公司，公司将在<span
lang=EN-US>15</span>日内回复您的请求。山东精准教育科技发展有限公司个人隐私安全保护专员来信地址：山东省济南市槐荫区日照路<span
lang=EN-US>2751</span>号齐鲁之门<span lang=EN-US>B2-B</span>座<span lang=EN-US>1103</span>室。</span></p>

<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

</div>

</body>

</html>
` ;
export default text_1;
import {
	fetch
} from "./index"; //引用fetch.js

//侧边栏 查询一本教辅有哪些页面报错了 哪些报错处理了
export function queryEffectiveImageVideos(params) {
	return fetch(`/osmdb/imageVideoRest/queryEffectiveImageVideos`, {
		method: 'post',
		data: params,
	})
}
//协议页面 查询用户协议和用户隐私协议
export function queryOneAppAgreement(params) {
	return fetch(`/osmdb/appRest/queryOneAppAgreement`, {
		method: 'post',
		data: params,
	})
}

